import {
  LEGACY_AJAX_LOGON_FORM_ROUTE,
  MY_ACCOUNT_SEARCH
} from '@app/constants/routes';
import { ACCOUNT_NAVIGATION_FLAG } from '@app/components/pages/control-center/constants';
import { useRouterDomNavigation } from '@app/hooks';
import { replaceTokensInString } from '@app/utils';
import queryString from 'query-string';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { useControlCenterChecker, useMyAccountLinks } from '..';
import { CONTROL_CENTER_BLOCK_FLAG } from '@app/constants/commonConstants';
import { getStaticData } from '../../cat-vantage-rewards/utils/getStaticData';

const useMyAccountBreadcrumbs = () => {
  const [t] = useTranslation();
  const { anchorNavigate } = useRouterDomNavigation();

  const { pathname, search } = useLocation();
  const { staticLinks, dynamicLinks } = useMyAccountLinks();
  const { isControlCenterEnabled } = useControlCenterChecker();

  const userDetailsAddress = useSelector(
    s => s.organizationsAndUsers?.userDetails?.address
  );
  const blocksNavigationFeatureFlag = useSelector(
    state => state.featureFlag[ACCOUNT_NAVIGATION_FLAG]
  );
  const isEnrolledInCatVantage = useSelector(
    s => s.catVantage?.isCatVantageEnrolled
  );
  const isCatVantageRewardsEnabled = useSelector(
    state => state.featureFlag.PCC_CatVantageRewardsFlag
  );
  const { pageHeader, pageBreadcrumb } = getStaticData(
    t,
    isEnrolledInCatVantage
  );
  const cvrLabel = blocksNavigationFeatureFlag ? pageBreadcrumb : pageHeader;

  const isControlCenterBlocksEnabled = useSelector(
    s => s.featureFlag?.[CONTROL_CENTER_BLOCK_FLAG]
  );
  return useMemo(() => {
    const getBreadcrumbLink = ({ label, url, route }, key) => {
      const shouldUseHardRefresh =
        route === staticLinks.HOME.route ||
        pathname !== LEGACY_AJAX_LOGON_FORM_ROUTE ||
        isControlCenterBlocksEnabled;

      return {
        key: key || route,
        label:
          route === MY_ACCOUNT_SEARCH.CAT_VANTAGE_REWARDS &&
          isCatVantageRewardsEnabled
            ? cvrLabel
            : label,
        value: url,
        ...(!shouldUseHardRefresh ? { onClick: anchorNavigate } : {})
      };
    };

    let baseAccountLink = isControlCenterEnabled
      ? getBreadcrumbLink(staticLinks.CONTROL_CENTER, 'controlcenter')
      : getBreadcrumbLink(staticLinks.MY_ACCOUNT, 'account');

    if (blocksNavigationFeatureFlag) {
      baseAccountLink = getBreadcrumbLink(staticLinks.PERSONAL_INFO, 'account');
    }

    const baseBreadCrumbsLinks = [
      getBreadcrumbLink(staticLinks.HOME, 'home'),
      baseAccountLink
    ];

    const findCurrentLink = (link, currentPage) => {
      let currentLink =
        link.route === currentPage &&
        link !== staticLinks.HOME &&
        link !== staticLinks.MY_ACCOUNT &&
        link !== staticLinks.CONTROL_CENTER;
      if (blocksNavigationFeatureFlag) {
        currentLink =
          link.route === currentPage &&
          link !== staticLinks.HOME &&
          link !== staticLinks.PERSONAL_INFO;
      }
      return currentLink;
    };

    const { route, ...params } = queryString.parse(search);
    const currentPage = route ?? pathname;
    const currentLink = Object.values(staticLinks).find(link =>
      findCurrentLink(link, currentPage)
    );

    if (currentLink) {
      return baseBreadCrumbsLinks.concat(getBreadcrumbLink(currentLink));
    }

    // Dynamic Cases
    switch (currentPage) {
      // Order History / Order Details
      case MY_ACCOUNT_SEARCH.ORDER_DETAILS: {
        const orderHistoryLink = staticLinks.ORDER_HISTORY;
        const orderNumber = params['dbsOrderId'] || params['orderId'];
        const label = `${t('2413_ORDER_SHIPMENT_ORDER_NUMBER')} ${orderNumber}`;
        return baseBreadCrumbsLinks.concat(
          getBreadcrumbLink(orderHistoryLink),
          { key: currentPage, label }
        );
      }

      // Order History / Order Details / Request Return
      case MY_ACCOUNT_SEARCH.RETURN_FORM: {
        const orderDetailLink = dynamicLinks.ORDER_DETAIL;
        const orderHistoryLink = staticLinks.ORDER_HISTORY;
        const { orderNumber, orderId } = params;

        const label = replaceTokensInString(
          t('RETURN_ORDER_BREADCRUMB'),
          orderNumber
        );
        return baseBreadCrumbsLinks.concat(
          getBreadcrumbLink(orderHistoryLink),
          getBreadcrumbLink({
            ...orderDetailLink,
            label: orderDetailLink.getLabel(orderNumber),
            url: orderDetailLink.getUrl({ dbsOrderId: orderNumber, orderId })
          }),
          { key: currentPage, label }
        );
      }

      // Order History / Order Detail / Order Status
      case MY_ACCOUNT_SEARCH.ORDER_STATUS: {
        const orderDetailLink = dynamicLinks.ORDER_DETAIL;
        const orderHistoryLink = staticLinks.ORDER_HISTORY;

        const { dbsOrderId, orderId } = params;
        const orderNumber = dbsOrderId || orderId;
        const label = t('2413_ORDER_SHIPMENT_ORDER_STATUS');

        return baseBreadCrumbsLinks.concat(
          getBreadcrumbLink(orderHistoryLink),
          getBreadcrumbLink({
            ...orderDetailLink,
            label: orderDetailLink.getLabel(orderNumber),
            url: orderDetailLink.getUrl({ dbsOrderId, orderId })
          }),
          { key: currentPage, label }
        );
      }

      // Orders to approve / CAL Pending Order Details
      case MY_ACCOUNT_SEARCH.PENDING_ORDER_DETAILS: {
        const ordersToApproveLink = staticLinks.ORDERS_TO_APPROVE;
        const orderNumber = params['orderId'];
        return baseBreadCrumbsLinks.concat(
          getBreadcrumbLink(ordersToApproveLink),
          { key: currentPage, label: orderNumber }
        );
      }

      // Saved List / Saved List Details
      case MY_ACCOUNT_SEARCH.LIST_DETAILS: {
        const savedListLink = staticLinks.SAVED_LIST;
        const listName = params['requisitionListName'];
        return baseBreadCrumbsLinks.concat(getBreadcrumbLink(savedListLink), {
          key: currentPage,
          label: listName
        });
      }

      // Organization and users / User Details
      case MY_ACCOUNT_SEARCH.ORGANIZATIONS_AND_USERS_DETAILS: {
        const orgsAndUsersLink = staticLinks.ORGANIZATIONS_AND_USERS;
        const fullName = `${userDetailsAddress?.firstName || ''} ${
          userDetailsAddress?.lastName || ''
        }`.trim();
        return baseBreadCrumbsLinks.concat(
          getBreadcrumbLink(orgsAndUsersLink),
          { key: currentPage, label: fullName }
        );
      }

      // Invoices / Invoice Details
      case MY_ACCOUNT_SEARCH.INVOICE_DETAILS: {
        const invoiceHistoryLink = staticLinks.INVOICES;
        const invoiceNumber = params['invoiceId'];
        const label = `${t('2413_INVOICE_NUMBER')} ${invoiceNumber}`;
        return baseBreadCrumbsLinks.concat(
          getBreadcrumbLink(invoiceHistoryLink),
          { key: currentPage, label }
        );
      }

      default:
        return baseBreadCrumbsLinks;
    }
  }, [
    t,
    anchorNavigate,
    pathname,
    search,
    staticLinks,
    dynamicLinks,
    userDetailsAddress,
    isControlCenterEnabled
  ]);
};

export default useMyAccountBreadcrumbs;
