import Conditional from '@app/components/common/Conditional';
import { isEmpty, isHomepage } from '@app/utils';
import { CatInlineNotification } from '@blocks-react/components/InlineNotification';
import { CatIconWarningTriangle } from '@blocks-react/components/Icons/WarningTriangle';
import { useSelector } from 'react-redux';
import { CUSTOM_OUTAGE_MESSAGE, PCC_EMERGENCY_MESSAGE } from '../constants';
import { lazy } from 'react';
import { useECT } from '@app/hooks';
import { EMERGENGY_MESSAGE_FLAG } from '@app/constants/featureFlags';

const CommonBannerNotification = lazy(
  () =>
    import(
      /* webpackChunkName: 'commonBannerNotification' */ '../CommonBannerNotification'
    )
);

const EmergencyMessage = () => {
  const flagEnabled = useSelector(s => s.featureFlag?.[EMERGENGY_MESSAGE_FLAG]);
  const EmergencyMessageEnabled = useSelector(
    state => state.dealer?.customOutageMessageEnabled
  );

  const { data: emergencyMessage } = useECT(
    PCC_EMERGENCY_MESSAGE,
    CUSTOM_OUTAGE_MESSAGE
  );

  let showEmergencyMessage;
  if (flagEnabled) {
    showEmergencyMessage =
      isHomepage() &&
      !isEmpty(emergencyMessage) &&
      !isEmpty(emergencyMessage?.text);
  } else {
    showEmergencyMessage =
      isHomepage() &&
      !!EmergencyMessageEnabled &&
      !isEmpty(emergencyMessage) &&
      !isEmpty(emergencyMessage?.text);
  }

  return (
    <Conditional test={showEmergencyMessage}>
      <div className="cat-u-margin-bottom-md">
        <CatInlineNotification
          heading={emergencyMessage?.text}
          variant="warning"
          dismissible={true}
          data-testid="custom-outage-message"
        >
          <CatIconWarningTriangle slot="before" />
        </CatInlineNotification>
      </div>
    </Conditional>
  );
};

export default EmergencyMessage;
