import { PCC_HEADLESS_SAVED_LISTS_PAGE } from '@app/constants/featureFlags';
import links, { headlessLinks } from '@app/constants/links';
import { normalizeUrl, replaceTokensInString } from '@app/utils';
import { useSelector } from 'react-redux';

const useSavedListsFeatureFlag = () => {
  const esiteURL = useSelector(s => s.common?.esiteURL);
  const storeId = useSelector(s => s.common?.storeId);
  const langId = useSelector(s => s.common?.langId);
  const catalogId = useSelector(s => s.common?.catalogId);
  const { SAVED_LISTS_URL } = links;
  const { HEADLESS_SAVED_LISTS } = headlessLinks;

  const isSavedListsFeatureFlagEnable =
    useSelector(s => s.featureFlag?.[PCC_HEADLESS_SAVED_LISTS_PAGE]) ?? false;

  return isSavedListsFeatureFlagEnable
    ? replaceTokensInString(HEADLESS_SAVED_LISTS, esiteURL)
    : normalizeUrl(
        replaceTokensInString(SAVED_LISTS_URL, catalogId, langId, storeId)
      );
};

export default useSavedListsFeatureFlag;
