export const FORM_ADD_EQ_MODAL_NAME = 'Step 1 | add equipment modal view';
export const FORM_ADD_EQ_SUGGESTION_SUBMITTED_NAME =
  'Step 2 | add equipment | search suggestion';
export const FORM_ADD_EQ_RESULT_SUBMITTED_NAME =
  'Step 2 | add equipment | search result';
export const FORM_AD_EQ_UPDATE_EQUIPMENT =
  'Step 3 | add equipment | update equipment';
export const FORM_SAVE_EQUIPMENT = 'Step 3 | add equipment | save equipment';
export const CSR_ORDER_SEARCH_FORM = 'CSR_ORDER_SEARCH_FORM';

export const EVENT_ADD_TO_CART = 'addToCart';
export const EVENT_CHECKOUT = 'checkout';
export const EVENT_PROCED_TO_CHECKOUT = 'beginCheckout';
export const EVENT_FORM_SUBMITTED = 'formSubmitted';
export const EVENT_FORM_VISIBLE = 'formVisible';
export const EVENT_IMPRESSION_READY = 'impressionReady';
export const EVENT_PRODUCT_CLICK = 'productClick';
export const EVENT_PRODUCT_DETAIL_READY = 'productDetailReady';
export const EVENT_PROMO_CLICK = 'promotionClick';
export const EVENT_PROMO_VIEW = 'promotionView';
export const EVENT_REFUND = 'refund';
export const EVENT_REMOVE_FROM_CART = 'removeFromCart';
export const EVENT_TRANSACTION_READY = 'transactionReady';
export const EVENT_FREIGHT_CALC = 'FreightCalc';

export const LIST_PCC_CART_PAGE = 'PCC Cart Page';
export const LIST_PCC_PDP_DIAGRAM = 'PCC PDP Diagram';
export const LIST_PCC_EDIT_QUOTE = 'PCC Edit Quote';
export const LIST_BUNDLES_PARTS_TABLE = 'SSRO Bundle - Parts Table';
export const LIST_BUNDLES_HEADER = 'SSRO Bundle -Component Configuration';
export const LIST_BUNDLES_RELATED_PARTS = 'SSO Bundle Related Parts';
export const LIST_BUNDLES_RECOMMENDED_TOOLS = 'SSO Bundle Rec. Tools';
export const LIST_MLP_REPAIR_BUNDLES = 'MLP SSO Repair Bundles - {0}';
export const LIST_MLP_TOP_SELLING_PARTS = 'MLP Top Selling Parts';

export const SYSTEM_ERROR = 'system error';
export const NO_RESULT_FOUND = 'no results found';

export const STATUS_FAIL = 'fail';
export const STATUS_SUCCESS = 'success';
export const STATUS_ERROR = 'error';
export const STATUS_FAILURE = 'failure';
export const MAX_PRICE_ERROR = 'priceRangeMax';

export const SAVED_LISTS_PAGE_PATH = 'SavedLists';
export const SAVED_LISTS_FORM_NAME = 'SavedListSearch';

export const ORDERS_TO_APPROVE_PAGE_PATH = 'OrderApprovalView';
export const ORDERS_TO_APPROVE_FORM_NAME = 'OrdersApproveSearch';

export const PENDING_QUOTES_PAGE_PATH = 'PendingQuoteView';
export const PENDING_QUOTES_FORM_NAME = 'quote search';
export const QUOTES_AWAITING_APPROVAL_FORM_NAME =
  'awaiting approval quote search';

export const USER_DETAILS_PAGE_PATH = 'OrganizationAdminEditUserView';
export const USER_DETAILS_FORM_NAME = 'Save User Details';

export const YES = 'yes';
export const NO = 'no';

export const SSO_KIT_ID = 'SSO-{0}';

export const FITMENT_TRUE = 'fity';
export const FITMENT_FALSE = 'fitn';

export const CAT_PART = 'Cat';
export const NON_CAT_PART = 'Non Cat';

export const PM_KIT_LIST_BUNDLES_PARTS_TABLE = 'PM Bundle Parts Table';
export const PM_KIT_LIST_BUNDLES_RELATED_PARTS = 'PM Bundle Related Parts';
export const PM_KIT_LIST_BUNDLES_RECOMMENDED_TOOLS =
  'PM Bundle Recommended Tools';
export const PLP_CATEGORY_GA_LIST_NAME = 'PCC Category Page';
export const LIST_SELECT_PM_KIT = 'PM Kit Widget - Select PM Kit';
export const ORDER_HISTORY_SEARCH = 'Order History Search';

export const PCC_MINI_CART_LIST = 'PCC Mini Cart';

export const PLACE_ORDER_ERROR = 'Place Order Error';

export const PCC_RV_LIST = 'PCC Recently Viewed';

export const PCC_SEARCH_RESULTS_LIST = 'PCC Search Results';
export const PCC_RECENT_SEARCH_RESULTS_LIST =
  'PCC recently viewed products Page';
export const PCC_FEATURE_SEARCH_RESULTS_LIST = 'PCC Featured product Page'


export const PCC_PDP_KITS_LIST = 'Prime PDP Kits';

export const PCC_PDP_BUNDLE_KITS_LIST = 'Prime PDP Bundle Kits';

export const PCC_BS_LIST = 'PCC Best Seller';

export const LIST_CHECKOUT_SUMMARY_AND_PAYMENT = 'PCC Order Summary Payment';

export const LIST_CHECKOUT_PICKUP_AND_DELIVERY = 'PCC Pickup and Delivery';

export const DEFAULT_BUSINESS_UNIT = 'PCC';

export const NOT_APPLICABLE = 'N/A';

export const FBT_LIST_NAME = 'PCC PDP Frequently Bought Together';

export const PCC_JOBS_RECOMMENDATIONS_LIST = 'PCC PDP Job Recommendations';

// Personalization Analytics Constants
export const PERSONALIZATION = {
  CAMPAIGN_TYPE: 'Personalization',
  USER_GROUP: 'control', //ALWAYS BE CONTROL FOR PERSONALIZATION
  STAT_TYPE_ALL: {
    CONTROL: 'false' // ALWAYS BE FALSE FOR PERSONALIZATION
  },
  EVENT_TYPE: {
    IMPRESSION: 'personalization_impression',
    CLICK: 'personalization_clickthrough',
    CLOSE: 'personalization_close'
  },
  // PersonalizationName
  TYPE: {
    APP_BANNER: 'RetailMobileApp'
  }
};

// Retail App Banner Constants
export const APP_BANNER = {
  CAMPAIGN_ID: {
    STATIC: 'Static Banner Retail and Small Core',
    SMART: 'Smart Banner Retail and Small Core'
  },
  TYPE: {
    STATIC: 'Static_Banner',
    SMART_INSTALL: 'System_Install_Banner',
    SMART_OPEN: 'System_Open_Banner'
  }
};

export const DEVICE_TYPE = {
  ANDROID: 'Android',
  IOS: 'IOS'
};

export const CONTROL_CENTER_PAGE_PATH = 'Control Center';

export const MAIN_NAV_TRACK = {
  EVENT: 'e_mainNav',
  TOP_MENU_CONTEXT: 'Top Menu',
  TOP_SUBMENU_CONTEXT: 'Top Sub Menu',
  ALL_CATEGORIES_TEXT: 'All Categories',
  CATEGORIES_TEXT: 'Categories ',
  HELP_CENTER_TEXT: 'Help center',
  LOGO_CLICK_TEXT: 'Logo Click',
  MORE_TEXT: 'More',
  PARTS_MANUAL_TEXT: 'Parts Manuals',
  PARTS_DIAGRAM_TEXT: 'Parts Diagram',
  QUICK_ORDER_TEXT: 'Quick Order',
  SIS_TEXT: 'SIS ',
  SPECIALS_TEXT: 'Specials',
  SHOP_ALL_CATEGORIES: 'Shop all categories',
  CONTROL_CENTER_TEXT: 'Control Center',
  PERSONAL_INFORMATION_TEXT: 'Personal Information',
  MY_EQUIPMENT_TEXT: 'My Equipment',
  ORDER_HISTORY_TEXT: 'Order History',
  ORDER_TO_APPROVE_TEXT: 'Order to Approve',
  PENDING_QUOTES_TEXT: 'Pending Quotes',
  SAVED_LISTS_TEXT: 'Saved Lists',
  NOTIFICATION_PREFERENCES_TEXT: 'Notification Preferences',
  SHOPPING_PREFERENCES_TEXT: 'Shopping Preferences',
  ADDRESS_BOOK_TEXT: 'Address Book',
  CUSTOMER_CARE_HOME_TEXT: 'Cat Customer Care Home',
  TRANSACT_AS_GUEST_TEXT: 'Transact As Guest'
};

export const CUSTOMER_SEARCH_FORM = 'CSR customer search';
export const FREIGHT_TAX_API = 'Freight Tax API';
export const PRICE_AND_AVAILABILITY_API = 'Price and Availability API';
export const SOURCE_OF_SUPPLY_API = 'Source of Supply API';
export const SOURCE_OF_SUPPLY_API_INITIAL_TIME = 'sourceSupplyApiInitialTime';
export const PROCEED_TO_CHECKOUT = 'Proceed to checkout';
export const CUSTOMER_LOOKUP_API = 'Customer Lookup API';
export const CUSTOMER_LOOKUP_API_INITIAL_TIME = 'customerLookupApiInitialTime';

export const TRACKING_ID = {
  DEV: 'UA-3257780-10',
  PROD: 'UA-31008602-1'
};

export const PLP_MUTATION_LOADER_SELECTOR = '[class^="Skeleton-"]';
export const PLP_MUTATION_PRICE_SELECTOR = '[data-price]';
export const PLACE_ORDER_TIME = 'placeOrderWithCreditCard';
export const ORDER_CONFIRMATION_TIME_EVENT = 'Order Confirmation';

export const RECENTLY_VIEWED_LOADER_SELECTOR = '[data-price-loader]';
export const RECENTLY_VIEWED_PRICE_SELECTOR = '[data-price]';

export const RECOMMENDED_TOOLS_LOADER_SELECTOR =
  '[data-loader-recommended-tools]';
export const RECOMMENDED_TOOLS_PRICE_SELECTOR =
  '[data-price-recommended-tools]';

export const PARTS_LIST_LOADER_SELECTOR = '[data-loader-parts]';
export const PARTS_LIST_PRICE_SELECTOR = '[data-price-parts]';

export const RELATED_PARTS_LOADER_SELECTOR = '[data-loader-related]';
export const RELATED_PARTS_PRICE_SELECTOR = '[data-price-related]';
export const GA_LIST_NAME = 'PCC Core Tracking page';

export const CVR_E_CERTIFICATE_TRACK = {
  EVENT: 'e_primaryCTA',
  ENTER_E_CERTIFICATE_TEXT: 'Enter E-Certificate number',
  CVR_CONTEXT: 'cat vantage rewards'
};

export const CVR_E_CERTIFCATE_NUMBER =
  'Cat vantage rewards – e-certificate number';

export const PCC_PAYMENT_PAGE = 'PCC Payment Page';

export const PCC_SERVICE_RECOMMENDATION_MODEL =
  'PCC Service Recommendation Modal';
