import React, { useState } from 'react';
import { ModalContext } from 'cat-ecommerce-alloy';
import { useDispatch, useSelector } from 'react-redux';
import CSRPotentialIssuesModal from '../../CSRPotentialIssuesModal';
import { setError } from '@app/store/exception/actions';
import { getDealerAssociations } from '@app/store/dealer-association/actions';
import { setCustomersRedirection } from '@app/store/customers/actions';
import { getHttpInstance } from '@app/services/defaultHttpService';
import { replaceTokensInUrl } from '@app/utils';
import { verifyDealerAssociation } from '../../utils';
import { useTranslation } from 'react-i18next';
import {
  CSR_CUSTOMER_SELECT_ERROR,
  ERROR_DOMAIN,
  ERROR_PATH
} from '@app/constants/errorConstants';
import endpoints from '@app/constants/endpoints';
import { CSR_ACTIONS } from '../../constants';
import useChangeAssociatedDealer from '@app/components/associated-dealer/useChangeAssociatedDealer';
import useDealerLocator from '@app/components/dealer-locator';
import { useModal } from '@cat-ecom/pcc-components';

const POTENTIAL_ISSUES_MODAL_ID = 'POTENTIAL_ISSUES_MODAL_ID';

const useBuyOnBehalf = () => {
  const { addModalWithOptions, removeCurrentModal } =
    React.useContext(ModalContext);
  const storeId = useSelector(state => state.common?.storeId);
  const dispatch = useDispatch();
  const { showAssociatedDealer } = useChangeAssociatedDealer();
  const { showDealerLocator } = useDealerLocator();
  const [t] = useTranslation();
  const [buyOnBehalfParameters, setBuyOnBehalfParameters] = useState({});

  const PCC_CustomerSupportTransactAsGuestFromCSRCartList =
    useSelector(
      state =>
        state?.featureFlag?.PCC_CustomerSupportTransactAsGuestFromCSRCartList
    ) || false;

  const dealerModalProps = useModal({
    isRequired: false,
    backButton: false,
    size: 'md'
  });

  const activateBuyOnBehalf = async (
    userId,
    newStoreId = false,
    userSetInSession = false
  ) => {
    const http = getHttpInstance();
    const storeIdParam = newStoreId ? newStoreId : storeId;
    const url = replaceTokensInUrl(
      endpoints.CSR_SET_USER_IN_SESSION,
      storeIdParam,
      userId
    );
    const userSetInSessionUrl = replaceTokensInUrl(
      endpoints.CSR_TABLE_SELECT_ENDPOINT,
      userId,
      storeIdParam
    );
    try {
      await http.get(url);
      if (userSetInSession) {
        await http.get(userSetInSessionUrl);
      }
    } catch (e) {
      dispatch(
        setError(
          ERROR_DOMAIN.CSR_LANDING_PAGE,
          ERROR_PATH.CSR_CONNECTION_FAIL,
          CSR_CUSTOMER_SELECT_ERROR
        )
      );
    }
  };

  const showBobModal = async (userId, reorderFunc) => {
    const redirectionPoint = CSR_ACTIONS.BUY_ON_BEHALF;
    const bobModalArgs = {
      adpSubmitPromise: (newStoreId, userSetInSession) =>
        activateBuyOnBehalf(userId, newStoreId, userSetInSession),
      bobRedirect: redirectionPoint,
      description: t('WELCOME_PCC_LOGGED_IN_NO_DEALER'),
      title: t('WELCOME_TO_PARTS_COM'),
      showAssociatedDealer,
      showDealerLocator,
      bobUserId: userId,
      reorderFunc
    };
    setBuyOnBehalfParameters({
      bobRedirect: bobModalArgs.bobRedirect,
      adpSubmitPromise: bobModalArgs.adpSubmitPromise,
      bobUserId: bobModalArgs.bobUserId,
      reorderFunc: bobModalArgs.reorderFunc
    });
    dispatch(setCustomersRedirection(redirectionPoint));
    dispatch(
      getDealerAssociations(
        { userId: userId },
        true,
        bobModalArgs,
        PCC_CustomerSupportTransactAsGuestFromCSRCartList,
        dealerModalProps.modalControl.open
      )
    );
  };

  const handleContinueModal = (userId, reorderFunc) => {
    removeCurrentModal();
    showBobModal(userId, reorderFunc);
  };

  const showPotentialIssuesModal = (userId, reorderFunc) => {
    addModalWithOptions(
      <CSRPotentialIssuesModal
        onCancel={() => removeCurrentModal()}
        onContinue={() => handleContinueModal(userId, reorderFunc)}
      />,
      {
        id: POTENTIAL_ISSUES_MODAL_ID
      }
    );
  };

  const handleConnectToCustomer = async (userId, reorderFunc) => {
    const { hasPotentialIssues, error } = await verifyDealerAssociation(
      storeId,
      userId
    );

    if (hasPotentialIssues && !error) {
      showPotentialIssuesModal(userId, reorderFunc);
    } else if (!hasPotentialIssues && !error) {
      showBobModal(userId, reorderFunc);
    } else if (error) {
      dispatch(
        setError(
          ERROR_DOMAIN.CSR_LANDING_PAGE,
          ERROR_PATH.CSR_CONNECTION_FAIL,
          CSR_CUSTOMER_SELECT_ERROR
        )
      );
    }
  };

  return { handleConnectToCustomer, dealerModalProps, buyOnBehalfParameters };
};

export default useBuyOnBehalf;
