export const BOTTOM_ESPOT_NAME = 'CAT_MLP_EMARKETINGSPOT_BOTTOM';
export const MIDDLE_ESPOT_NAME = 'CAT_MLP_EMARKETINGSPOT_MIDDLE';
export const TEMP_EQUIPMENT_STATUS = 'temp';
export const SAVE_MY_EQUIPMENT = 'SAVE_MY_EQUIPMENT';
export const EDIT_MY_EQUIPMENT = 'EDIT_MY_EQUIPMENT';
export const ALP_SELECT_DEALER_FLYOUT_SHOWN = 'ALP_SELECT_DEALER_FLYOUT_SHOWN';
export const DESKTOP_MAX_LIMIT_PARTS_BY_CATEGORY = 10;
export const MOBILE_MAX_LIMIT_PARTS_BY_CATEGORY = 8;
export const CAT_CARD_REWARD_LINK = 'cat.com/catcardrewards';
export const SIS2GO_GET_STARTED =
  'https://www.cat.com/en_US/support/maintenance/sis2go-app.html';
export const EQUIPMENT_SAVE_STATUS_SESSION = 'equipmentSaveStatus';
export const CAT_INSPECT_LINK = 'https://www.cat.com/catinspect';
export const REGISTER_FOR_ENGINE_WARRANTY =
  'https://www.cat.com/{0}/support/maintenance/engine-warranty-registration.html?serialNumber={1}&salesModel={2}';
export const GENERIC_WARRANTY =
  'https://www.cat.com/{0}/support/maintenance/warranty.html';
export const LOCALE_SPECIFIC_WARRANTY =
  'https://www.cat.com/en_US/support/maintenance/warranty.html';

export const WARRANTY_PAGE_EXCLUDED = [
  'ar_EG',
  'in_ID',
  'da_DK',
  'nb_NO',
  'vi_VN',
  'cs_CZ',
  'el_GR',
  'bg_BG',
  'he_IL',
  'hi_IN',
  'bn_BD',
  'ta_IN',
  'th_TH',
  'kn_IN',
  'fi_FI'
];
export const SELECT_DEALER_FLYOUT_IMG = {
  src: 'https://caterpillar.scene7.com/is/image/Caterpillar/CM20231102-0e19b-fccd0?hei=110',
  alt: 'Select Dealer'
};

export const REQUIST_SERVICE_CALENDER = {
  MIN_DATE: 0,
  MAX_DATE: 30
};
