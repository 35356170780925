import {
  CVR_ENROLLED_IMG,
  CVR_NOT_ENROLLED_IMG,
  ENROLLED_CAT_VANTAGE_STAR,
  NOT_ENROLLED_CAT_VANTAGE_STAR
} from '@app/constants/commonConstants';
import { replaceTokensInString, replaceTokensWithComponents } from '@app/utils';

export const getStaticData = (t, isEnrolled = false, supStyle = {}) => {
  const tradeMarkSymbol = '®';
  const superscriptOfRegisteredTrademarkSymbol = (
    <sup style={supStyle}>{tradeMarkSymbol}</sup>
  );
  const catVantage = <span translate="no">{' Cat Vantage '}</span>;
  const catVantageRewards = (
    <span translate="no">{' Cat Vantage Rewards '}</span>
  );
  const catVantageRewardCopyright = (
    <span translate="no">
      {replaceTokensWithComponents(
        'Cat{0} Vantage Rewards',
        superscriptOfRegisteredTrademarkSymbol
      )}
    </span>
  );

  const catVantageRewardCopyrightTitle = replaceTokensInString(
    'Cat{0} Vantage Rewards',
    tradeMarkSymbol
  );
  const catVantagePointsTitle = 'Cat Vantage Points';
  const joinCatVantageRewardCopyrightTitle = replaceTokensInString(
    t('JOIN_CAT_VANTAGE_REWARDS'),
    catVantageRewardCopyrightTitle
  );
  const CVRGlobalNavLabel = replaceTokensInString(
    t('GET_REWARDED_EARN_CAT_VANTAGE_POINTS'),
    catVantagePointsTitle
  );

  const insertRegisterestedTradeMarkSymbol = translation => {
    return replaceTokensWithComponents(
      t(translation),
      superscriptOfRegisteredTrademarkSymbol
    );
  };

  const insertCatVantageSpan = translation => {
    return replaceTokensWithComponents(t(translation), catVantage);
  };

  const insertCatVantageRewardSpan = translation => {
    return replaceTokensWithComponents(t(translation), catVantageRewards);
  };

  const insertCatVantageRewardCopyrightSpan = translation => {
    return replaceTokensWithComponents(
      t(translation),
      catVantageRewardCopyright
    );
  };

  const starImage = isEnrolled
    ? ENROLLED_CAT_VANTAGE_STAR
    : NOT_ENROLLED_CAT_VANTAGE_STAR;

  const rewardsImage = isEnrolled ? CVR_ENROLLED_IMG : CVR_NOT_ENROLLED_IMG;

  const joinCatVantageRewardsCopyright = insertCatVantageRewardCopyrightSpan(
    'JOIN_CAT_VANTAGE_REWARDS'
  );
  const pageHeader = isEnrolled
    ? catVantageRewardCopyright
    : joinCatVantageRewardsCopyright;

  const pageBreadcrumb = isEnrolled
    ? catVantageRewardCopyrightTitle
    : joinCatVantageRewardCopyrightTitle;

  const pageLabel = isEnrolled ? 'CAT_VANTAGE_REWARDS' : 'JOIN_CVR_BUTTON';

  const pageDescription = isEnrolled ? 'SHOP_EARN_REDEEM' : 'EARN_REWARD_TITLE';
  const rewardInfo = isEnrolled
    ? t('REWARD_POINTS_INFO')
    : insertCatVantageRewardCopyrightSpan('EARN_REWARD_INFO');
  const summaryAndCheckboxInfo = isEnrolled
    ? insertCatVantageRewardCopyrightSpan('CVR_SUMMARY')
    : t('CHECKBOX_TITLE');

  return {
    starImage,
    rewardsImage,
    pageHeader,
    pageBreadcrumb,
    pageLabel,
    pageDescription,
    rewardInfo,
    summaryAndCheckboxInfo,
    catVantageRewardCopyright,
    joinCatVantageRewardsCopyright,
    catVantageRewards,
    catVantageRewardCopyrightTitle,
    joinCatVantageRewardCopyrightTitle,
    insertCatVantageSpan,
    insertRegisterestedTradeMarkSymbol,
    insertCatVantageRewardSpan,
    insertCatVantageRewardCopyrightSpan,
    CVRGlobalNavLabel
  };
};
