import { format } from 'date-fns';
import { isEmpty } from '@app/utils';
import { isEqual, uniqueId } from 'lodash';
import { v4 } from 'uuid';
import { AttachmentIcon } from 'cat-ecommerce-alloy';
import {
  OFFLINE_STATUS_MAP,
  BILLING_METHOD_CAT_PREPAID_CERT,
  DELIVERY_TYPE,
  DROP_BOX_TYPE,
  PICK_UP_TYPE,
  SHOP_ONLINE_TYPE,
  ORDER_STATUS_MAP,
  ORDER_TYPES,
  ORDER_STATUS_NOT_AVAILABLE,
  DROPBOX_METHOD,
  ADDRESS,
  DROPBOX_ADDRESS,
  METHOD,
  INSTRUCTION,
  DROPBOX_INSTRUCTION
} from '@app/constants/commonConstants';
import {
  PENDING_ORDER,
  APPROVED_ORDER,
  STATUS_EXPIRED,
  STATUS_H,
  STATUS_W
} from '@app/components/pages/account/common/OrderHistoryTable/constants';
import { DATE_FNS_LOCALES } from '@app/constants/languageConstants';
import { joinByProperty } from '@app/utils/commonUtils';
import { getCountryDisplayName } from '@app/components/common/address/utils';

const buildStringValue = (fields, char) => fields.filter(n => n).join(char);

export const getOrderConfirmationInformation = ({
  orderData,
  requestByDate,
  showReferenceId,
  t,
  isShowingAccountNumber
}) => {
  const orderApprovalStatus = orderData?.calActionInfo?.status;
  const approvalStatusLabels = {
    Approved: 'APPROVED_STATUS',
    Deleted: 'DELETED_STATUS',
    Pending: 'PENDING_STATUS',
    Rejected: 'REJECTED_STATUS'
  };

  const approverId = orderData?.calActionInfo?.approverId;

  const getApproverInfo = (orderApproverInfo = [], approverId = '') => {
    const approverInfo = orderApproverInfo.find(
      item => item.approverId === approverId
    );
    return approverInfo?.firstName.concat(' ', approverInfo?.lastName);
  };

  const shouldDisplayEquipment = (data, translateKey) =>
    data && `${t(translateKey)} - ${data}`;

  /*
   * The originalOrderReferenceNumber exists only in CAL Order Edit+Approve flow.
   * The referenceId is different than the orderId for this scenario.
   */
  const referenceId =
    orderData?.editedOrderInfo?.originalOrderReferenceNumber ||
    orderData?.orderId;

  return [
    {
      label: isShowingAccountNumber ? t('ShippingChargeAcctNum') : '',
      value: isShowingAccountNumber
        ? orderData?.dcnInfo?.customerNumber.concat(
            '-',
            orderData?.dcnInfo?.customerName
          )
        : '',
      contentSquareMask: true
    },
    {
      label: t('2413_ORDER_SHIPMENT_ORDER_STATUS'),
      value: !!orderApprovalStatus
        ? t(approvalStatusLabels[orderApprovalStatus])
        : ''
    },
    {
      label: t('APPROVED_BY'),
      value: getApproverInfo(orderData?.approverInfo, approverId)
    },
    {
      label: t('REFERENCE_ID'),
      value: showReferenceId ? referenceId : '',
      contentSquareMask: true
    },
    {
      label: t('MA_OH_STORE_LOC'),
      value: orderData?.storeLocation?.storeName
    },
    {
      label: t('CAT_EQUIPMENT'),
      value: [
        shouldDisplayEquipment(orderData?.equipmentInfo?.assetName, 'ASSET_ID'),
        shouldDisplayEquipment(orderData?.equipmentInfo?.model, 'PM_MODEL_NUM'),
        shouldDisplayEquipment(
          orderData?.equipmentInfo?.serialNumber,
          'PM_SERIAL_NUM'
        )
      ]
    },
    {
      label: t('CAT_SHOPPING_PREFERENCES_ORDER_TYPE'),
      value: orderData?.orderType?.label
    },
    {
      label: t('CAT_SHOPPING_PREFERENCES_END_USE_CODE'),
      value: orderData?.endUseCode?.label
    },
    {
      label: t('ORDERED_BY_LABEL'),
      value: orderData.orderByCSRName
        ? `${orderData.orderByCSRName} ${t('FROM_CUSTOMER_SUPPORT')}`
        : orderData?.orderedByInfo?.name,
      contentSquareMask: true
    },
    {
      label: t('CAT_EMAIL_EMAIL1'),
      value: orderData?.orderedByInfo?.email,
      contentSquareMask: true
    },
    {
      label: t('MOSB_PHONE_NUMBER'),
      value: orderData?.orderedByInfo?.phone,
      contentSquareMask: true
    },
    {
      label: t('EDIT_QUOTE_ORIG_QUOTE'),
      value:
        orderData?.originalQuoteInfo?.activatedQuoteDocNumber ||
        orderData?.originalQuoteInfo?.quoteDocumentNumber
    },
    {
      label: t('REQUEST_BY_DATE'),
      value: requestByDate
    }
  ];
};

export const getOrderInformation = ({
  localeCode,
  orderData,
  orderType,
  isCatCSR,
  isShipmentTrackingEnabled,
  requestByDate,
  t
}) => {
  const orderApprovalStatus = orderData?.approvalInfo?.approvalStatus;
  const originalOrderReferenceNumber =
    orderData?.approvalInfo?.originalOrderReferenceNumber;

  const shouldDisplayStatus =
    orderApprovalStatus !== PENDING_ORDER && orderApprovalStatus !== null;
  const showOrderNumberTBD = orderData?.orderStatus === STATUS_W;

  const orderExtendAttribute = orderData?.orderExtendAttribute;
  const orderedByCSRName = orderExtendAttribute?.orderByCSRName || '';
  const orderedByCSRValue = `${orderedByCSRName}${
    orderedByCSRName && ` ${t('FROM_CUSTOMER_SUPPORT')}`
  }`;
  const approvalStatusLabels = {
    approved: 'APPROVED_BY',
    rejected: 'REJECTED_BY',
    deleted: 'DELETED_BY'
  };
  const dateFormat = date => {
    return `${format(new Date(date), 'MMMM d, yyyy, hh:mm a', {
      locale: localeCode ? DATE_FNS_LOCALES[localeCode] : DATE_FNS_LOCALES.en_US
    })}`;
  };

  const formattedDate = orderData?.orderPlacedDate
    ? dateFormat(orderData?.orderPlacedDate)
    : '';
  const submittedDateFormat = orderData?.orderSubmittedDate
    ? dateFormat(orderData?.orderSubmittedDate)
    : '';
  const assetId = !!orderData?.equipment?.assetId
    ? `${t('ASSET_ID')} - ${orderData?.equipment?.assetId}`
    : '';
  const modelNumber = !!orderData?.equipment?.model
    ? `${t('PM_MODEL_NUM')} - ${orderData?.equipment?.model}`
    : '';
  const serialNumber = !!orderData?.equipment?.serialNumber
    ? `${t('PM_SERIAL_NUM')} - ${orderData?.equipment?.serialNumber}`
    : '';
  const isOrderExpired = orderData?.orderStatus === STATUS_EXPIRED;
  const isOrderQueue = orderData?.orderStatus === STATUS_H;
  const orderDate = isOrderExpired ? submittedDateFormat : formattedDate;
  const orderStatusValue =
    orderData?.orderStatus &&
    (orderType === ORDER_TYPES.OFFLINE_ORDER
      ? t(OFFLINE_STATUS_MAP[orderData?.orderStatus])
      : t(ORDER_STATUS_MAP[orderData?.orderStatus]));

  const orderStatusValidation =
    !isShipmentTrackingEnabled && isCatCSR
      ? ORDER_STATUS_NOT_AVAILABLE
      : orderStatusValue;

  const orderStatusObject = {
    id: v4(),
    label: t('2413_ORDER_SHIPMENT_ORDER_STATUS'),
    value: orderStatusValidation
  };
  const referenceId =
    !!orderApprovalStatus || isOrderExpired || isOrderQueue
      ? orderData?.orderId
      : '';
  const referenceIdObject = {
    id: v4(),
    label: t('ORDER_QUEUED_REFERENCE_NUMBER'),
    value:
      orderApprovalStatus === APPROVED_ORDER && !!originalOrderReferenceNumber
        ? originalOrderReferenceNumber
        : referenceId
  };

  const getOrderInvoiceNumber = () => {
    if (orderData?.invoiceNumber) {
      return (
        <a href={orderData?.invoiceDetailsLink}>{orderData?.invoiceNumber}</a>
      );
    } else {
      return t('UNAVAILABLE');
    }
  };

  let orderInformation = [
    {
      id: v4(),
      label: t('INVOICE_NUMBER'),
      value: getOrderInvoiceNumber()
    },
    {
      id: v4(),
      label: t('MA_ORDERNUM'),
      value: showOrderNumberTBD
        ? t('TO_BE_DETERMINED')
        : orderData?.orderDocumentNumber
    },
    {
      id: v4(),
      label: t('MA_ORDER_DATE'),
      value: orderDate
    },
    {
      id: v4(),
      label: orderData?.requestByDate?.requestByDateEnabled
        ? t('REQUESTED_DELIVERY_DATE')
        : '',
      value: orderData?.requestByDate?.requestByDateEnabled ? requestByDate : ''
    },
    {
      id: v4(),
      label: t('CFCA_ACCOUNT_NUMBER'),
      value: orderData?.orderExtendAttribute?.accountNumber
    },
    {
      id: v4(),
      label: shouldDisplayStatus
        ? t(approvalStatusLabels[orderApprovalStatus])
        : '',
      value: shouldDisplayStatus ? orderData?.approvalInfo?.approverName : ''
    },
    isOrderExpired ? orderStatusObject : referenceIdObject,
    {
      id: v4(),
      label: t('SUMM_PAY_CARD_EXPIRATION_DATE'),
      value: orderExtendAttribute?.calOrderExpireTime
    },
    isOrderExpired ? referenceIdObject : orderStatusObject,
    {
      id: v4(),
      label: t('MA_OH_STORE_LOC'),
      value: buildStringValue(
        [orderExtendAttribute?.dealerName, orderExtendAttribute?.dealerStore],
        ` - `
      )
    },
    {
      id: v4(),
      label: t('CAT_EQUIPMENT'),
      value: [assetId, modelNumber, serialNumber]
    },
    {
      id: v4(),
      label: t('CAT_SHOPPING_PREFERENCES_ORDER_TYPE'),
      value: orderExtendAttribute?.orderType
    },
    {
      id: v4(),
      label: t('CAT_SHOPPING_PREFERENCES_END_USE_CODE'),
      value: orderData?.endUseCode
    },
    {
      id: v4(),
      label: t('ORDERED_BY_LABEL'),
      value: !!orderedByCSRValue
        ? orderedByCSRValue
        : orderExtendAttribute?.orderByName
    },
    {
      id: v4(),
      label: t('OFFLINE_ORD_CUST'),
      value: orderExtendAttribute?.customerName
    },
    {
      id: v4(),
      label: t('CAT_EMAIL_EMAIL1'),
      value:
        orderType === ORDER_TYPES.OFFLINE_ORDER
          ? orderExtendAttribute?.customerEmail
          : orderExtendAttribute?.orderByEmail,
      contentSquareMask: true
    },
    {
      id: v4(),
      label: t('CAT_EMAIL_PHONE1'),
      value:
        orderType === ORDER_TYPES.OFFLINE_ORDER
          ? orderExtendAttribute?.customerPhone
          : orderExtendAttribute?.orderByPhone,
      contentSquareMask: true
    },
    {
      id: v4(),
      label: t('EDIT_QUOTE_ORIG_QUOTE'),
      value:
        orderExtendAttribute?.activatedQuoteDocNumber ||
        orderExtendAttribute?.originalQuoteNumber
    }
  ];

  if (isCatCSR && !isOrderExpired) {
    // move reference to first spot
    orderInformation = orderInformation.filter(
      item => item.label !== t('ORDER_QUEUED_REFERENCE_NUMBER')
    );
    orderInformation.unshift(referenceIdObject);
  }

  return orderInformation;
};

export const getInvoiceDetailInformationArray = invoiceData => {
  const {
    orderNumber,
    formattedInvoiceDate,
    formattedDocumentDate,
    invoiceSequenceNumber,
    salesRepresentative,
    division,
    machineId,
    accountNumber,
    equipmentNumber,
    dealerStore,
    equipment,
    orderDetailsLink
  } = invoiceData || {};
  return [
    {
      label: 'ORDHISTORY_ORD_ORDER_NUMBER',
      value: orderNumber,
      orderLink: orderDetailsLink
    },
    {
      label: 'INVOICE_DATE',
      value: formattedInvoiceDate
    },
    {
      label: 'DOCUMENT_DATE',
      value: formattedDocumentDate
    },
    {
      label: 'INVOICE_SEQUENCE_NUMBER',
      value: invoiceSequenceNumber
    },
    {
      label: 'SALES_REPRESENTATIVE',
      value: salesRepresentative
    },
    {
      label: 'DIVISION',
      value: division
    },
    {
      label: 'MA_OH_MACHINE_ID',
      value: machineId
    },
    {
      label: 'EQUIPMENT_NUMBER',
      value: equipmentNumber
    },
    {
      label: 'CAT_MA_UCID_WITHOUT_COLON',
      value: accountNumber
    },
    {
      label: 'CAT_SHOPPING_PREFERENCES_DLR_STORE',
      value: dealerStore
    },
    {
      label: 'CAT_EQUIPMENT',
      value: `[${equipment?.model} - ${equipment?.serialNumber}]`
    }
  ];
};

export const getInvoiceDetailCustomerInformationArray = invoiceData => {
  const { customerName, shippingAddress } = invoiceData || {};
  const { address1, address2, address3, address4, address5 } =
    shippingAddress || {};

  return [
    {
      label: 'DLR_QUOTE_FIELD_LABEL_TITLE',
      value: customerName
    },
    {
      label: 'SHIP_TO_CSR',
      value: [
        address4,
        address1,
        buildStringValue([address2, address3], ','),
        address5
      ]
    }
  ];
};

export const getInvoiceDetailBillingAndPaymentArray = invoiceData => {
  const { billingMethod, poNumber, billingAddress } = invoiceData || {};
  const { address1, address2, cityState, country, name, zipcode } =
    billingAddress || {};

  return [
    {
      label: 'BILL_BILLING_METHOD',
      value: billingMethod
    },
    {
      label: 'BILLINGADDRESS',
      value: [
        name,
        address1,
        address2,
        buildStringValue([cityState, zipcode], ','),
        country
      ],
      contentSquareMask: true
    },
    {
      label: 'BILL_PO_METHOD_COLON',
      value: poNumber
    }
  ];
};

export const getPickupAndDeliveryArray = (
  pickupDeliveryInformation,
  pickupDeliveryType,
  dealerStore,
  orderType,
  t,
  dropboxLabel,
  estimatedFormattedDateValue
) => {
  const deliveryOrShopOnlineData = {
    domain: 'shippingAddress',
    addressLabel: t('QC_SHIPPINGADDR'),
    methodLabel: t('SHIP_SHIPPING_METHOD'),
    estimatedFulfillmentLabel: t('ESTIMATED_DELIVERY_DATE')
  };

  const fullfillmentData = {
    delivery: deliveryOrShopOnlineData,
    shopOnline: deliveryOrShopOnlineData,
    dropBox: {
      domain: 'dropBoxAddress',
      addressLabel: dropboxLabel
        ? `${dropboxLabel} ${ADDRESS} `
        : DROPBOX_ADDRESS,
      methodLabel: dropboxLabel ? `${dropboxLabel} ${METHOD}` : DROPBOX_METHOD,
      estimatedFulfillmentLabel: t('ESTIMATED_FULFILLMENT_DATE')
    },
    pickup: {
      domain: 'storeAddress',
      addressLabel: t('MO_STORE_LOCATION'),
      methodLabel: t('CHECKOUT_PICKUP_METHOD'),
      estimatedFulfillmentLabel: t('ESTIMATED_PICKUP_DATE')
    }
  };

  const pickupDeliveryDataDomain =
    pickupDeliveryInformation?.[fullfillmentData[pickupDeliveryType]?.domain];

  const cityAndStateValue = buildStringValue(
    [pickupDeliveryDataDomain?.city, pickupDeliveryDataDomain?.state],
    `, `
  );
  const pickupDeliveryLocationValue = buildStringValue(
    [cityAndStateValue, pickupDeliveryDataDomain?.zipcode],
    ` `
  );

  return [
    {
      id: v4(),
      label: fullfillmentData[pickupDeliveryType]?.estimatedFulfillmentLabel,
      value: estimatedFormattedDateValue,
      contentSquareMask: true
    },
    {
      id: v4(),
      label: fullfillmentData[pickupDeliveryType]?.methodLabel,
      value: pickupDeliveryInformation?.method,
      contentSquareMask: true
    },
    {
      id: v4(),
      label:
        orderType === ORDER_TYPES.OFFLINE_ORDER
          ? t('OFFLINE_ORD_SP_LOC')
          : fullfillmentData[pickupDeliveryType]?.addressLabel,
      value: [
        pickupDeliveryType === PICK_UP_TYPE ? t('MO_STORE_PICK_UP_MSG') : '',
        pickupDeliveryType === PICK_UP_TYPE ? dealerStore : '',
        buildStringValue(
          [
            pickupDeliveryDataDomain?.firstName,
            pickupDeliveryDataDomain?.lastName
          ],
          ` `
        ),
        buildStringValue(
          [
            pickupDeliveryDataDomain?.address1,
            pickupDeliveryDataDomain?.address2
          ],
          ` `
        ),
        pickupDeliveryLocationValue,
        pickupDeliveryDataDomain?.country,
        pickupDeliveryDataDomain?.phone,
        pickupDeliveryDataDomain?.email,
        pickupDeliveryDataDomain?.nickname
      ],
      contentSquareMask: true
    },
    {
      id: v4(),
      label:
        orderType === ORDER_TYPES.OFFLINE_ORDER
          ? t('OFFLINE_ORD_SP_INST')
          : t('CAT_SHOPPING_PREFERENCES_PICKUP_INSTRUCTION'),
      value: pickupDeliveryInformation?.instructions
    },
    {
      id: v4(),
      label: dropboxLabel
        ? `${dropboxLabel} ${INSTRUCTION} `
        : DROPBOX_INSTRUCTION,
      value: pickupDeliveryInformation?.dropBoxInstructions,
      contentSquareMask: true
    },
    {
      id: v4(),
      label:
        orderType === ORDER_TYPES.OFFLINE_ORDER
          ? t('OFFLINE_ORD_SP_INST')
          : t('CAT_SHOPPING_PREFERENCES_SHIP_INSTRUCTION'),
      value: pickupDeliveryInformation?.shippingInstructions
    },
    {
      id: v4(),
      label: t('RES_SPL_INST'),
      value: pickupDeliveryInformation?.specialnstructions,
      contentSquareMask: true
    }
  ];
};

export const getPickupAndDeliveryConfirmationArray = (
  pickupDeliveryInformation,
  pickupDeliveryType,
  dealerStore,
  orderType,
  t,
  dropboxLabel,
  estimatedFormattedDateValue
) => {
  const deliveryTypeData = {
    delivery: {
      domain: 'shippingDetails',
      addressDomain: 'customerInfo',
      addressLabel: t('QC_SHIPPINGADDR'),
      methodLabel: t('SHIP_SHIPPING_METHOD'),
      instructionLabel: t('CAT_SHOPPING_PREFERENCES_SHIP_INSTRUCTION'),
      estimatedFulfillmentLabel: t('ESTIMATED_DELIVERY_DATE')
    },
    dropBox: {
      domain: 'dropBoxDetails',
      addressDomain: 'dropBoxInfo',
      addressLabel: dropboxLabel
        ? `${dropboxLabel} ${ADDRESS} `
        : DROPBOX_ADDRESS,
      methodLabel: dropboxLabel ? `${dropboxLabel} ${METHOD} ` : DROPBOX_METHOD,
      instructionLabel: dropboxLabel
        ? `${dropboxLabel} ${INSTRUCTION} `
        : DROPBOX_INSTRUCTION,
      estimatedFulfillmentLabel: t('ESTIMATED_FULFILLMENT_DATE')
    },
    pickup: {
      domain: 'storeDetails',
      addressDomain: 'storeContactInfo',
      addressLabel: t('MO_STORE_LOCATION'),
      methodLabel: t('CHECKOUT_PICKUP_METHOD'),
      instructionLabel: t('CAT_SHOPPING_PREFERENCES_PICKUP_INSTRUCTION'),
      estimatedFulfillmentLabel: t('ESTIMATED_PICKUP_DATE')
    }
  };

  const pickupDeliveryDataDomain =
    pickupDeliveryInformation?.[deliveryTypeData[pickupDeliveryType]?.domain];
  const addressDomain = deliveryTypeData[pickupDeliveryType]?.addressDomain;

  const cityAndStateValue = buildStringValue(
    [
      pickupDeliveryDataDomain?.address?.city,
      pickupDeliveryDataDomain?.address?.state
    ],
    `, `
  );

  const pickupDeliveryLocationValue = buildStringValue(
    [cityAndStateValue, pickupDeliveryDataDomain?.address?.zipCode],
    ` `
  );

  return [
    {
      label: deliveryTypeData[pickupDeliveryType]?.estimatedFulfillmentLabel,
      value: estimatedFormattedDateValue,
      contentSquareMask: true
    },
    {
      label: deliveryTypeData[pickupDeliveryType]?.methodLabel,
      value: pickupDeliveryInformation?.methodLabel,
      contentSquareMask: true
    },
    {
      label: deliveryTypeData[pickupDeliveryType]?.addressLabel,
      value: [
        pickupDeliveryType === PICK_UP_TYPE ? t('MO_STORE_PICK_UP_MSG') : '',
        pickupDeliveryType === PICK_UP_TYPE ? dealerStore : '',
        buildStringValue(
          [
            pickupDeliveryDataDomain?.[addressDomain]?.firstName,
            pickupDeliveryDataDomain?.[addressDomain]?.lastName
          ],
          ` `
        ),
        buildStringValue([pickupDeliveryDataDomain?.address?.line1], ` `),
        buildStringValue([pickupDeliveryDataDomain?.address?.line2], ` `),
        pickupDeliveryLocationValue,
        pickupDeliveryDataDomain?.address?.country,
        pickupDeliveryDataDomain?.[addressDomain]?.phone,
        pickupDeliveryDataDomain?.[addressDomain]?.email
      ],
      contentSquareMask: true
    },
    {
      label: deliveryTypeData[pickupDeliveryType]?.instructionLabel,
      value: pickupDeliveryInformation?.instructionsLabel,
      contentSquareMask: true
    },
    {
      label: t('RES_SPL_INST'),
      value: pickupDeliveryInformation?.specialInstructions,
      contentSquareMask: true
    }
  ];
};

export const getBillingAndPaymentArray = (orderData, t) => {
  const poAttachments = orderData?.purchaseOrderInfo?.poAttachmentName ?? [];
  const billingAddressInfo = orderData?.billingAddress;
  const attachmentsNames =
    poAttachments.length > 0
      ? poAttachments.map(({ fileName }) => fileName)
      : '';

  const cityAndStateValue = buildStringValue(
    [billingAddressInfo?.city, billingAddressInfo?.state],
    `, `
  );
  const billingLocationValue = buildStringValue(
    [cityAndStateValue, billingAddressInfo?.zipCode],
    ` `
  );

  const billingMethodValue = orderData?.additionalBillingMethod
    ? buildStringValue(
        [orderData?.additionalBillingMethod, orderData?.billingMethod],
        `, `
      )
    : orderData?.billingMethod;

  return [
    {
      id: v4(),
      label: t('BILL_BILLING_METHOD'),
      value: billingMethodValue
    },
    {
      id: v4(),
      label: t('BILLINGADDRESS'),
      value: [
        buildStringValue(
          [billingAddressInfo?.firstName, billingAddressInfo?.lastName],
          ` `
        ),
        buildStringValue(
          [billingAddressInfo?.address1, billingAddressInfo?.address2],
          ` `
        ),
        billingLocationValue,
        billingAddressInfo?.country,
        orderData?.billingMethod != t('CAT_DEALERPYMTTYPES_DealerCredit') &&
        orderData?.billingMethod != t('CAT_DEALERPYMTTYPES_COD') &&
        orderData?.billingMethod != t('CAT_DEALERPYMTTYPES_Cash/Check')
          ? billingAddressInfo?.phone ||
            orderData?.orderExtendAttribute?.orderByPhone
          : '',
        orderData?.billingMethod != t('CAT_DEALERPYMTTYPES_DealerCredit') &&
        orderData?.billingMethod != t('CAT_DEALERPYMTTYPES_COD') &&
        orderData?.billingMethod != t('CAT_DEALERPYMTTYPES_Cash/Check')
          ? billingAddressInfo?.email
          : ''
      ],
      contentSquareMask: true
    },
    {
      id: v4(),
      label: t('CAT_ATTACHMENT'),
      value: attachmentsNames
    },
    {
      id: v4(),
      label: t('BILL_PO_METHOD_COLON'),
      value: orderData?.purchaseOrderInfo?.poNumber
    },
    {
      id: v4(),
      label: t('PROMOTION_CODE_TITLE'),
      value: orderData?.orderExtendAttribute?.appliedPromotionCode
    }
  ];
};

export const getBillingAndPaymentConfirmationArray = (
  orderData,
  t,
  countryInfo,
  getStateData,
  state
) => {
  const billingAddressInfo = orderData?.billingAndPayment?.billingAddress;

  const promotionCode =
    orderData?.promoCodeInfo?.promoCodeFromCartImport ||
    orderData?.promoCodeInfo?.appliedPromotionCode ||
    null;

  const countryName = getCountryDisplayName(
    countryInfo,
    billingAddressInfo?.country
  );
  getStateData(countryName, billingAddressInfo?.zipCode);
  const cityAndStateValue = buildStringValue(
    [billingAddressInfo?.city, state || billingAddressInfo?.state],
    `, `
  );
  const billingLocationValue = buildStringValue(
    [cityAndStateValue, billingAddressInfo?.zipCode],
    ` `
  );

  const getAttachmentFileName = poAttachments =>
    poAttachments?.map(({ fileName }) => (
      <span
        className="text-uppercase d-flex align-items-center"
        key={uniqueId('attachment-')}
      >
        <AttachmentIcon className="me-1" height={16} width={16} />
        {fileName}
      </span>
    ));

  const attachmentValue = getAttachmentFileName(
    orderData?.purchaseOrderInfo?.poAttachments
  );

  const arrangeBillingMethods = (billingMethods = []) => {
    const catCreditsValue = billingMethods.find(
      billingMethod => billingMethod.code === BILLING_METHOD_CAT_PREPAID_CERT
    );
    return [
      ...(catCreditsValue ? [catCreditsValue] : []),
      ...billingMethods.filter(
        billingMethod => billingMethod.code !== BILLING_METHOD_CAT_PREPAID_CERT
      )
    ];
  };

  const billingMethodValue = joinByProperty(
    arrangeBillingMethods(orderData?.billingAndPayment?.paymentMethodInfo),
    'label'
  );

  return [
    {
      label: t('BILL_BILLING_METHOD'),
      value: billingMethodValue,
      contentSquareMask: true
    },
    {
      label: t('BILLINGADDRESS'),
      value: [
        buildStringValue(
          [billingAddressInfo?.firstName, billingAddressInfo?.lastName],
          ` `
        ),
        buildStringValue([billingAddressInfo?.line1], ` `),
        buildStringValue([billingAddressInfo?.line2], ` `),
        billingLocationValue,
        countryName,
        billingAddressInfo?.phone,
        billingAddressInfo?.email
      ],
      contentSquareMask: true
    },
    {
      label: t('CAT_ATTACHMENT'),
      value: attachmentValue,
      contentSquareMask: true
    },
    {
      label: t('BILL_PO_METHOD_COLON'),
      value: orderData?.purchaseOrderInfo?.poNumber,
      contentSquareMask: true
    },
    {
      label: t('PROMOTION_CODE_TITLE'),
      value: promotionCode,
      contentSquareMask: true
    }
  ];
};

export const getInstructionsArray = orderItems => {
  let instructionsArray = [];
  if (!isEmpty(orderItems)) {
    orderItems.forEach(orderItem => {
      const itemInstructions =
        orderItem?.orderItemExtendAttribute?.ssroBundle || [];
      if (!isEmpty(itemInstructions)) {
        itemInstructions.forEach(instruction => {
          const isNewInstruction = !instructionsArray?.find(
            existentInstruction => isEqual(existentInstruction, instruction)
          );
          if (isNewInstruction) {
            instructionsArray.push(instruction);
          }
        });
      }
    });
  }
  return instructionsArray;
};

export const getfullfillmentDomain = orderType => {
  return orderType === DELIVERY_TYPE ? SHOP_ONLINE_TYPE : orderType;
};

export const getTypeFulfillmentInfo = (orderInformation, fulfillmentProp) => {
  /* 
    If "type" property exists fulfillment type is "shopOnLine" (PR68397)   
    If the "type" property does not exists fulfillment type is "delivery"   
  */
  const pickupDeliveryType =
    !!orderInformation && orderInformation[fulfillmentProp]?.type;
  let typeValidation = '';
  if (orderInformation) {
    if (pickupDeliveryType) {
      typeValidation =
        pickupDeliveryType === DELIVERY_TYPE
          ? SHOP_ONLINE_TYPE
          : pickupDeliveryType;
    } else {
      if (orderInformation.fulfillment?.pickup) {
        typeValidation = PICK_UP_TYPE;
      } else if (orderInformation?.fulfillment?.delivery) {
        typeValidation = DELIVERY_TYPE;
      } else if (orderInformation?.fulfillment?.shopOnline) {
        typeValidation = SHOP_ONLINE_TYPE;
      } else {
        typeValidation = DROP_BOX_TYPE;
      }
    }
  }

  return typeValidation;
};

export const getInstructionsArrayOrderConfirmation = (orderItems = []) => {
  let instructionsArray = [];
  orderItems.forEach(({ ssroBundle } = []) => {
    ssroBundle.forEach((instruction = []) => {
      const isNewInstruction = !instructionsArray?.find(existentInstruction =>
        isEqual(existentInstruction, instruction)
      );
      if (isNewInstruction) {
        instructionsArray.push(instruction);
      }
    });
  });
  return instructionsArray;
};
