import { STATUS, PAGE_BREAKPOINTS } from '@app/constants/commonConstants';
import Conditional from '@app/components/common/Conditional';
import { isEmpty } from '@app/utils';
import {
  HeaderSearchBar,
  HeaderSearchBarNoResults,
  HeaderSearchBarSuggestion,
  HeaderSearchBarSuggestionList,
  HeaderEquipmentSearchIcon
} from '@cat-ecom/pcc-components';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import useSearchSuggestions from '../hooks/useSearchSuggestions';
import { useBreakpoint } from '@app/hooks';
import { useTracking } from 'react-tracking';
import { getFormSubmitEvent } from '@app/components/common/analytics/analyticsUtils';
import HeaderSearchMobileView from '../HeaderSearchMobileView';
import HeaderEquipmentWidgetWrapper from '../HeaderEquipmentWidgetWrapper';
import PropTypes from 'prop-types';

const HeaderSearchBarWrapper = ({
  subLabel = '',
  initialSearchValue = '',
  onSearchCallback = null,
  onBlMobileOverlayOpen,
  onBlMobileOverlayClose
}) => {
  const { t } = useTranslation();
  const { trackEvent } = useTracking();
  const categoryLabel = t('MEQ_SUG_CAT');
  const productLabel = t('SUGGESTED_PRODUCTS');
  const keywordLabel = t('SUGGESTED_KEYWORDS');
  const placeholder = t('TOP_NAV_SEARCH');
  const noSuggestedResults = t('NO_SUGGESTED_RESULTS_TRY_AGAIN');
  const { onSearch, getDebouncedSuggestions, searchValue } =
    useSearchSuggestions();

  const searchedValue = initialSearchValue || searchValue;

  const suggestedCategories =
    useSelector(
      s => s.partsFilter?.filter?.searchSuggestions?.suggestedCategories
    ) || [];

  const suggestedProducts =
    useSelector(
      s => s.partsFilter?.filter?.searchSuggestions?.suggestedProducts
    ) || [];

  const suggestedKeywords =
    useSelector(
      s => s.partsFilter?.filter?.searchSuggestions?.suggestedKeyWords
    ) || [];

  const suggestionsStatus = useSelector(s => s.partsFilter?.suggestionsStatus);

  const hasNoResults = isEmpty([
    ...suggestedCategories,
    ...suggestedProducts,
    ...suggestedKeywords
  ]);
  const isStatusIdle = suggestionsStatus === STATUS.IDLE;
  const isMobile = !useBreakpoint(PAGE_BREAKPOINTS.BLOCKS_LG);
  const isTabletAndMobileLayout = !useBreakpoint(PAGE_BREAKPOINTS.BLOCKS_LG);
  const dropDownPanelMessage = searchedValue ? noSuggestedResults : '';

  const trackItemSelection = displayName => {
    trackEvent(
      getFormSubmitEvent({
        formName: 'search',
        formContent: searchedValue,
        formContent2: displayName
      })
    );
  };

  const handleOnSearch = (searchValue, enteredSearchKeyword = '') => {
    if (!searchValue) {
      return;
    }
    if (onSearchCallback) {
      onSearchCallback(enteredSearchKeyword);
    }
    trackEvent(
      getFormSubmitEvent({ formName: 'search', formContent: searchValue })
    );
    onSearch(searchValue);
  };

  const handleOnChange = (searchValue, enteredSearchKeyword = '') => {
    if (onSearchCallback) {
      onSearchCallback(enteredSearchKeyword);
    }
    getDebouncedSuggestions(searchValue);
  };

  return (
    <HeaderSearchBar
      placeholder={placeholder}
      onSearch={handleOnSearch}
      onChange={handleOnChange}
      hideResults={isStatusIdle}
      isMobile={isMobile}
      mobileBreakPoint={PAGE_BREAKPOINTS.BLOCKS_LG}
      size={subLabel ? 'lg' : 'md'}
      initialSearchValue={initialSearchValue}
      onBlMobileOverlayOpen={onBlMobileOverlayOpen}
      onBlMobileOverlayClose={onBlMobileOverlayClose}
    >
      <Conditional test={isTabletAndMobileLayout}>
        <HeaderSearchMobileView />
      </Conditional>
      {hasNoResults ? (
        <HeaderSearchBarNoResults
          message={dropDownPanelMessage}
          isMobile={isMobile}
        />
      ) : (
        <HeaderSearchBarSuggestionList>
          <HeaderSearchBarSuggestion
            label={keywordLabel}
            items={suggestedKeywords}
            highlightText={searchedValue}
            onItemClick={trackItemSelection}
          />
          <HeaderSearchBarSuggestion
            label={productLabel}
            items={suggestedProducts}
            highlightText={searchedValue}
            onItemClick={trackItemSelection}
          />
          <HeaderSearchBarSuggestion
            label={categoryLabel}
            items={suggestedCategories}
            highlightText={searchedValue}
            onItemClick={trackItemSelection}
          />
        </HeaderSearchBarSuggestionList>
      )}
      <Conditional test={!isMobile}>
        <HeaderEquipmentWidgetWrapper subLabel={subLabel} />
        <HeaderEquipmentSearchIcon
          size={subLabel ? 'lg' : 'md'}
          onClick={() => handleOnSearch(searchValue)}
          subLabel={subLabel}
        />
      </Conditional>
    </HeaderSearchBar>
  );
};

HeaderSearchBarWrapper.propTypes = {
  subLabel: PropTypes.string,
  initialSearchValue: PropTypes.string,
  onSearchCallback: PropTypes.func,
  onBlMobileOverlayOpen: PropTypes.func,
  onBlMobileOverlayClose: PropTypes.func
};

export default HeaderSearchBarWrapper;
