import { FooterCommonLink } from '@cat-ecom/pcc-components';
import { useTranslation } from 'react-i18next';
import { USER_TYPE_GUEST } from '@app/constants/commonConstants';
import links from '@app/constants/links';
import { replaceTokensInString, normalizeUrl } from '@app/utils';
import { useSelector } from 'react-redux';
import { useControlCenterChecker } from '@app/components/pages/account/hooks';
import useControlCenterUrl from '@app/hooks/useControlCenterUrl';
import useShoppingCartFeatureFlag from '@app/hooks/useShoppingCartFeatureFlag';
import useSavedListsFeatureFlag from '@app/hooks/useSavedListsFeatureFlag';

const FooterAccountLinks = () => {
  const { t } = useTranslation();
  const storeId = useSelector(state => state?.common?.storeId);
  const langId = useSelector(state => state?.common?.langId);
  const catalogId = useSelector(state => state?.common?.catalogId);
  const userType = useSelector(state => state?.common?.userType);
  const { isControlCenterEnabled } = useControlCenterChecker();
  const isUserRegistered = userType !== USER_TYPE_GUEST;
  const { MA_SUMMARY_URL, MA_ORDER_HISTORY_URL } = links;

  const getLinkUrl = url => {
    const link = replaceTokensInString(url, catalogId, langId, storeId);
    return normalizeUrl(link);
  };
  const controlCenterUrl = useControlCenterUrl();
  const shoppingCartUrl = useShoppingCartFeatureFlag();
  const savedListsUrl = useSavedListsFeatureFlag()

  const getAccountLink = () => (
    <li>
      {isControlCenterEnabled ? (
        <FooterCommonLink label={t('CONTROL_CENTER')} href={controlCenterUrl} />
      ) : (
        <FooterCommonLink
          label={t('SUMMARY')}
          href={getLinkUrl(MA_SUMMARY_URL)}
        />
      )}
    </li>
  );

  return (
    <>
      {isUserRegistered && getAccountLink()}
      <li>
        <FooterCommonLink
          label={t('MINI_SHOPCART_SHOPPING_CART')}
          href={shoppingCartUrl}
        />
      </li>
      {isUserRegistered && (
        <li>
          <FooterCommonLink
            label={t('MA_ORDER_HISTORY')}
            href={getLinkUrl(MA_ORDER_HISTORY_URL)}
          />
        </li>
      )}
      {isUserRegistered && (
        <li>
          <FooterCommonLink
            label={t('SAVED_LISTS')}
            href={savedListsUrl}
          />
        </li>
      )}
    </>
  );
};

export default FooterAccountLinks;
